import axios from 'axios';
import { API } from '../../api';
import qs from 'query-string';
import { IIncidentSearch } from '../../interfaces/IIncidents';

class IncidentSearchService {
  private _api = API.config.batman;

  public getSearchResults(
    query: {
      q: (string | undefined)[];
      filter?: (string | undefined)[];
      sort?: string;
    },
    collection: 'incidents' | 'postmortems',
  ) {
    return axios.get<{ data: IIncidentSearch }>(
      `${this._api}/organizations/${API.config.organizationId}/search?team_id=${
        API.config.teamId
      }&${qs.stringify(query)}&collection=${collection}&size=40`,
    );
  }
}

export default IncidentSearchService;
