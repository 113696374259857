import Axios from 'axios';
import { API } from 'core';
import { TaskAndRunbookService } from 'core';
import qs from 'query-string';
import { AppConfig } from 'shared/app.config';

import { getWorkflowRequestData } from '../helper';
import { Workflow } from '../types/response/workflow';
import {
  ActionTypeDetails,
  WorkflowDataResponse,
  WorkflowForm,
  WorkflowUpdateData,
  ActionRunBook,
  WorkflowListAPIParams,
} from '../types';
import { WorkflowLogsPagination } from '../types/requests';
import { WorkflowLogDetail } from '../types/response';
import { WEBHOOK_LIST_RESPONSE } from 'core/interfaces/IEventWebHooks';
import { IExtensionStatus, IJiraResponse } from 'core/interfaces/IExtensions';
import { getUserAccessStore, setUserAccessStore } from 'core/userAccess/UserAccessStore';

const headers = {
  'Content-Type': 'application/json',
};

const createWorkflowApi = async (data: WorkflowForm & { actions: ActionTypeDetails[] }) => {
  const body = getWorkflowRequestData(data);
  const { data: response } = await Axios.post(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow`,
    body,
    {
      headers,
    },
  );
  return response;
};

export const getWorkFlowByIdApi = async (id: string) => {
  const { data: response } = await Axios.get<Workflow>(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/${id}`,
    {
      headers,
    },
  );
  const acl = response?.metadata?.acl ?? {};
  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      workflows: {
        ...getUserAccessStore().entityACLMap?.workflows,
        ...acl,
      },
    },
  });
  return response;
};

const workflowListApi = (params: WorkflowListAPIParams) => async () => {
  const { data: response } = await Axios.get<WorkflowDataResponse>(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow`,
    {
      params,
      paramsSerializer: (params: WorkflowListAPIParams) => {
        const formattedParams = { ...params, tags: params.tags?.map(t => JSON.stringify(t)) };
        return qs.stringify(formattedParams, { arrayFormat: 'none' });
      },
    },
  );
  const acl = response?.metadata?.acl ?? {};
  setUserAccessStore({
    entityACLMap: {
      ...getUserAccessStore().entityACLMap,
      workflows: {
        ...getUserAccessStore().entityACLMap?.workflows,
        ...acl,
      },
    },
  });
  return response;
};

export type WorkFlowUpdateAPIParams = {
  id: string;
  formDirty?: boolean;
  formData?: WorkflowForm & { actions: ActionTypeDetails[] };
  partialUpdate?: Partial<WorkflowUpdateData>;
};
const workflowUpdateApi = async ({
  id,
  formDirty,
  formData,
  partialUpdate,
}: WorkFlowUpdateAPIParams) => {
  const body = formData
    ? getWorkflowRequestData(formData)
    : (partialUpdate as Partial<WorkflowUpdateData>);

  const { actions, ...rest } = body;
  const finalBody = formDirty ? body : rest;
  const { data: response } = await Axios.patch(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/${id}`,
    finalBody,
  );
  return response;
};

const fetchManualWebhooksApi = async () => {
  const { data: response } = await Axios.get<{ data: WEBHOOK_LIST_RESPONSE }>(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/extensions/event-webhooks`,
  );
  return response.data.result;
};

const workflowBulkEnableApi = async ({
  enabled,
  workflow_ids,
}: {
  enabled: boolean;
  workflow_ids: string[];
}) => {
  const { data: response } = await Axios.put(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/bulk/enable`,
    { enabled, workflow_ids },
  );
  return response;
};

const workflowCloneApi = async ({ workflow_id }: { workflow_id: string }) => {
  const { data: response } = await Axios.post(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/clone`,
    { workflow_id },
  );
  return response;
};

const workflowDeleteApi = async ({ id }: { id: string }) => {
  const { data: response } = await Axios.delete(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/${id}`,
  );
  return response;
};

const workflowlogActionsApi = async ({
  workflowId,
  logId,
}: {
  workflowId: string;
  logId: string;
}) => {
  const { data: response } = await Axios.get<WorkflowLogDetail[]>(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/${workflowId}/logs/${logId}`,
  );
  return response;
};

let runbookService: TaskAndRunbookService;
const runbooksApi = async () => {
  if (!runbookService) {
    runbookService = new TaskAndRunbookService('');
  }
  const { data: runbooks } = await runbookService.fetchOrganizationRunbook();
  return runbooks.data as ActionRunBook[];
};

const getWorkFlowLogsApi = async (id: string, pagination: WorkflowLogsPagination) => {
  const { data: response } = await Axios.get(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/${id}/logs`,
    {
      params: pagination,
    },
  );
  return response;
};
const getWorkFlowLogWebhook = async (id: string, execution_id: string, action_id: string) => {
  const { data: response } = await Axios.get(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/${id}/logs/${execution_id}/action/${action_id}/detail`,
  );
  return response;
};

const workflowTagsApi = async () => {
  const { data: response } = await Axios.get<{
    tags: { key: string; value: string; color: string }[];
  }>(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/tags`,
  );

  const tagsMap = response.tags.reduce((acc, tag) => {
    acc[tag.key] = acc[tag.key] ? [...acc[tag.key], tag.value] : [tag.value];
    return acc;
  }, {} as Record<string, string[]>);
  return Object.entries(tagsMap).map(([key, values]) => ({ key, values }));
};

const workflowCountApi = async () => {
  const { data: response } = await Axios.get(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/teams/${API.config.teamId}/workflow/count`,
  );
  return response;
};

const slackStatusApi = async ({ slackv2Enabled }: { slackv2Enabled: boolean }) => {
  const {
    data: {
      data: { status },
    },
  } = await Axios.get<{ data: { status: IExtensionStatus } }>(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/extensions/${
      slackv2Enabled ? 'slack_v2' : 'slack'
    }/status`,
    {
      headers,
    },
  );
  return status;
};

const msTeamStatusApi = async () => {
  const res = await Axios.get(
    `${AppConfig.api_url}/v3/organizations/${API.config.organizationId}/extensions/msteams/config`,
    {
      headers,
    },
  );
  return res;
};

const jiraStatusApi = async () => {
  const {
    data: { data: jiraData },
  } = await Axios.get<{
    data: IJiraResponse[];
  }>(`${API.config.batman}/organizations/${API.config.organizationId}/extensions/jira-cloud/new`, {
    headers,
  });

  return jiraData;
};

const checkSlackValidationApi = async ({ slackId }: { slackId: string }) => {
  const { data } = await Axios.get(
    `${API.config.batman}/organizations/${API.config.organizationId}/extensions/slack_v2/channel/validate/${slackId}`,
  );
  return data;
};
export {
  createWorkflowApi,
  workflowListApi,
  workflowUpdateApi,
  workflowDeleteApi,
  getWorkFlowLogsApi,
  workflowBulkEnableApi,
  workflowCloneApi,
  workflowlogActionsApi,
  runbooksApi,
  workflowTagsApi,
  getWorkFlowLogWebhook,
  fetchManualWebhooksApi,
  workflowCountApi,
  slackStatusApi,
  msTeamStatusApi,
  jiraStatusApi,
  checkSlackValidationApi,
};
