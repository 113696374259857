import { OwnerType } from 'library/molecules/Form/EntityOwnerDropdown/helper';
import {
  COMMUNICATION_CHANNEL_OPTIONS,
  OperatorOptions,
  TRIGGER_OPTIONS,
  LOGICAL_OPERATOR_OPTIONS,
} from '.';
import {
  ActionTypes,
  DrawerType,
  IncidentNoteAction,
  SqCommunicationChannelAction,
  SqCommunicationChannelLink,
  SubActionTypes,
  WorkflowForm,
  SloAffectingAction,
  SliOption,
  SqReassignIncidentAction,
  RunbookAction,
  ManualTriggerWebhook,
  HttpCallAction,
  EmailAction,
  MSTeamMessageChannelAction,
  MSTeamMessageUserAction,
  SlackCreateAction,
  SlackUserAction,
  SlackMessageAction,
  JiraTicketCreateAction,
  PriorityAction,
  StatusPageAction,
  MSTeamCreateMeetingLink,
} from '../types';

export const tagFilterInitValue = {
  logicalOperator: LOGICAL_OPERATOR_OPTIONS[0],
  operator: OperatorOptions[0],
  tag: {},
};

export const filterInitValue = {
  filters: {
    operator: OperatorOptions[0],
  },
};

export const workflowFormInitValues: WorkflowForm = {
  title: '',
  description: '',
  owner: {
    id: '',
    type: OwnerType.Team,
  },
  trigger: TRIGGER_OPTIONS[0],
  tags: [],
  group: { type: 'empty', fields: { children: [] } },
};

export const drawerInitValue = {
  isOpen: false,
  drawerType: null as DrawerType | null,
  prevDrawerType: null as ActionTypes | null,
};

export const communicationChannelLinkInit: SqCommunicationChannelLink = {
  link: '',
  channelType: COMMUNICATION_CHANNEL_OPTIONS[0],
};

export const communicationChannelInitValues: SqCommunicationChannelAction = {
  name: SubActionTypes.COMMUNICATION_CHANNEL,
  data: { channels: [communicationChannelLinkInit] },
};

export const incidentNoteInitValues: IncidentNoteAction = {
  name: SubActionTypes.INCIDENT_NOTE,
  data: {
    note: '',
  },
};

export const reassignIncidentInitValues: SqReassignIncidentAction = {
  name: SubActionTypes.REASSIGN_INCIDENT,
};

export const sloAffectingInitValues: SloAffectingAction = {
  name: SubActionTypes.SLO_AFFECTED,
  data: {
    sli: [] as SliOption[],
  },
};

export const runbookInitValues: RunbookAction = {
  name: SubActionTypes.RUNBOOKS,
  data: { runbooks: [] },
};
export const webhookInitValues: ManualTriggerWebhook = {
  name: SubActionTypes.TRIGGER_MANUAL_WEBHOOK,
  data: {
    id: '',
  },
};

export const httpCallInitValues: HttpCallAction = {
  name: SubActionTypes.HTTP_CALL,
  data: {
    method: '',
    url: '',
    body: '',
    headers: [{ key: '', value: '' }],
  },
};

export const emailInitValues: EmailAction = {
  name: SubActionTypes.SEND_EMAIL,
  data: {
    to: [],
    subject: '',
    body: '',
  },
};

export const priorityInitValues: PriorityAction = {
  name: SubActionTypes.PRIORITY,
  data: {
    priority: '',
  },
};
export const statusPageInitValues: StatusPageAction = {
  name: SubActionTypes.STATUS_PAGE,
  data: {
    status_page_id: -1,
    issue_title: '',
    page_status_id: -1,
    component_and_impact: [],
    status_and_message: [],
  },
};

export const messageTeamChannelInitValues: MSTeamMessageChannelAction = {
  name: SubActionTypes.MS_TEAMS_MESSAGE_CHANNEL,
  data: {
    channel_id: '',
    message: '',
  },
};
export const messageTeamUserInitValues: MSTeamMessageUserAction = {
  name: SubActionTypes.MS_TEAMS_MESSAGE_USER,
  data: {
    member_id: '',
    message: '',
  },
};

export const createMSTeamsMeetingLinkValues: MSTeamCreateMeetingLink = {
  name: SubActionTypes.MS_TEAMS_CREATE_MEETING_LINK,
};

export const createSlackInitValues: SlackCreateAction = {
  name: SubActionTypes.SLACK_CHANNEL_CREATE,
  data: {
    auto_name: false,
    channel_name: '',
  },
};

export const jiraTicketInitValues: JiraTicketCreateAction = {
  name: SubActionTypes.JIRA_CREATE_TICKET,
  data: {
    account: '',
    project: '',
    issue_type: '',
    title: '',
    description: '',
  },
};

export const messageUserInitValues: SlackUserAction = {
  name: SubActionTypes.SLACK_MESSAGE_USER,
  data: {
    member_id: '',
    message: '',
  },
};

export const messageChannelInitValues: SlackMessageAction = {
  name: SubActionTypes.SLACK_MESSAGE_CHANNEL,
  data: {
    channel_id: '',
    channel_name: '',
    message: '',
  },
};
